<template>
    <ShoppingPacket />
</template>

<script>
import {
    mapActions
} from 'vuex'

import ShoppingPacket from '../components/ShoppingPacketPage.vue'

export default {
    data: () => ({
    }),
    components: {
       ShoppingPacket
    },
    methods: {
    },
    mounted() {
    }
}
</script>
